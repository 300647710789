/*
 * @Author: fuxiaohui
 * @Date: 2021-11-23 17:53:17
 * @Last Modified by: fuxiaohui
 * @Last Modified time: 2021-12-01 10:25:21
 * @Description: 变更类型为框架类协议金额修改-新增页面
 */
<template>
  <div class="amount-modify">
    <el-form
        ref="formData"
        :model="formData"
        :rules="formRules"
        label-width="120px"
        size="small"
      >
      <!-- 合同基本信息 start -->
      <div class="contract-info">
        <el-row :gutter="10">
          <el-col :span="6">
            <el-form-item label="合同类型:">
              <el-input
                type="text"
                size="small"
                v-model="formData.contractTypeValue"
                disabled
              >
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="合同名称:" prop="contractName">
              <el-input
                type="text"
                size="small"
                placeholder="请输入合同名称"
                v-model="formData.contractName"
                @keydown.enter.native.prevent="handle"
              >
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="经办人:" prop="operatorName">
              <el-input
                type="text"
                size="small"
                v-model="formData.operatorName"
                disabled
              >
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6" max-height="60px">
            <el-form-item label="经办日期:" prop="contractDate">
              <el-date-picker
                v-model="formData.contractDate"
                type="date"
                placeholder="选择日期"
                value-format="yyyy-MM-dd"
                @change="handelContractDateChange"
              >
              </el-date-picker>
              <span class="date-tip" v-show="isShowTips">请注意：所选日期早于当前日期</span>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="10">
          <el-col :span="6">
            <el-form-item label="关联项目:" prop="projectGoalFlag">
              <el-select
                v-model="formData.projectGoalFlag"
                style="width: 100%"
                disabled
              >
                <el-option label="非项目目的" value="N" />
                <el-option label="项目目的" value="Y" />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item class="correlate" label-width="0">
              <el-input
                disabled
                placeholder="-"
                v-if="formData.projectGoalFlag === 'N'"
              />
              <el-input v-else disabled v-model="formData.projectName" />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="是否需要付款:" prop="needPay">
              <el-radio-group v-model="formData.needPay" disabled>
                <el-radio label="Y">是</el-radio>
                <el-radio label="N">否</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <!-- 是否供方合同 -->
          <el-col :span="6">
            <el-form-item label="是否供方合同:" prop="supplyStoreFlag">
              <el-radio-group
                v-model="formData.supplyStoreFlag"
                disabled
              >
                <el-radio label="1">是</el-radio>
                <el-radio label="0">否</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="10">
          <el-col :span="6">
            <el-form-item label="形成方式:">
              <el-select v-model="formData.forType" style="width: 100%" disabled placeholder="-">
                <template v-for="(item, index) of forTypeList">
                  <el-option
                    :key="index"
                    :label="item.label"
                    :value="item.code"
                  />
                </template>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6" v-if="modifyData.kgStage">
            <el-form-item
              label="所属阶段:"
              prop="fixContractType"
            >
              <el-select v-model="modifyData.kgStage" disabled>
                <el-option label="投资阶段" value="1"/>
                <el-option label="退出阶段" value="2"/>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6" v-if="modifyData.isRelationFixPj == 1">
            <el-form-item
              label="具体合同类型:"
              prop="fixContractType"
            >
              <el-select v-model="formData.fixContractType" disabled>
                <el-option label="CapEX合同" value="1"/>
                <el-option label="OpEX合同" value="2"/>
                <el-option label="通用合同" value="0"/>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </div>
      <!-- 合同基本信息 end -->

      <!-- 签约主体 start -->
      <div class="detail-card">
        <header class="card-header">
          <h4 class="second-title">签约主体</h4>
        </header>
        <section class="card-content">
          <el-row :gutter="10">
            <el-col :span="12">
              <el-form-item
                label="我方签约主体:"
                prop="ourCompany_modify"
                disabled
              >
                <el-select
                  multiple
                  placeholder="请输入不少于4个字的主体名称进行查询"
                  v-model="formData.ourCompany_modify"
                  style="width: 100%"
                  disabled
                >
                  <template v-for="item of subList.T01">
                    <el-option
                      :key="item.id"
                      :label="item.name"
                      :value="item.id"
                    />
                  </template>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item
                label="对方签约主体:"
                prop="supplierCompany_modify"
                disabled
              >
                <el-select
                  multiple
                  placeholder="请输入对方签约主体名称"
                  v-model="formData.supplierCompany_modify"
                  style="width: 100%"
                  ref="supplierCompany"
                  disabled
                >
                  <div slot="" class="option-wrapper">
                    <template>
                      <el-option
                        v-for="(item, index) of subList.T02"
                        :key="index"
                        :label="item.name"
                        :value="item.id"
                      >
                      </el-option>
                    </template>
                  </div>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
        </section>
      </div>
      <!-- 签约主体 end -->

      <!-- 合同金额 start -->
      <div class="detail-card">
        <header class="card-header">
          <h4 class="second-title">合同金额</h4>
        </header>
        <section class="card-content">
          <el-row :gutter="20">
            <el-col :span="6">
              <el-form-item label="修改类型:" prop="modifyMoneyType">
                <el-select
                  v-model="formData.modifyMoneyType"
                  style="width: 100%"
                  :clearable="true"
                  @change="handleModifyMoneyTypeChange($event)"
                >
                  <template v-for="(item, index) of modifyMoneyTypeList">
                    <el-option
                      :key="index"
                      :label="item.label"
                      :value="item.value"
                    />
                  </template>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="本次修改金额:" prop="modifyMoneyNum">
                <el-input
                  class="amount-input"
                  placeholder="请输入金额"
                  v-model="formData.modifyMoneyNum"
                  @keydown.enter.native.prevent="handle"
                  @input="handleModifyMoneyNumChange($event)"
                />
              </el-form-item>
            </el-col>
          </el-row>
        </section>
      </div>
      <!-- 合同金额 end -->

      <!-- 变更后 start-->
      <div class="detail-card">
        <header class="card-header">
          <h4 class="second-title">变更后</h4>
        </header>
        <section class="card-content">
          <el-row :gutter="20">
            <el-col :span="6">
              <el-form-item label="币种:" prop="currency">
                <el-select
                  v-model="formData.currency"
                  style="width: 100%"
                  :disabled="true"
                  :clearable="true"
                >
                  <template v-for="(item, index) of currencyList">
                    <el-option
                      :key="index"
                      :label="item.value"
                      :value="item.code"
                    />
                  </template>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item
                prop="contractAmount"
                class="label-style"
              >
                <span slot="label">合同金额(含税)<br />(A):</span>
                <el-input
                  class="amount-input"
                  placeholder="请输入金额"
                  v-model="formData.contractAmount"
                  @input="handleContractAmountChange($event)"
                  @keydown.enter.native.prevent="handle"
                />
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item
                prop="costAmount"
                class="label-style"
              >
                <span slot="label">不计成本金额<br />(B):</span>
                <el-input
                  class="amount-input"
                  placeholder="请输入金额"
                  v-model="formData.costAmount"
                  @input="limitInputModify($event, 'costAmount')"
                  @keydown.enter.native.prevent="handle"
                />
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item
                prop="effContractAmount"
                class="label-style"
              >
                <span slot="label">有效签约金额<br />(C=A-B):</span>
                <el-input
                  class="amount-input"
                  placeholder="-"
                  v-model="formData.effContractAmount"
                  disabled
                />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="6">
              <el-form-item label="发票类型:" prop="invoiceType">
                <el-select v-model="formData.invoiceType" style="width: 100%">
                  <template v-for="(item, index) of invoiceTypeList">
                    <el-option
                      :key="index"
                      :label="item.label"
                      :value="item.code"
                    />
                  </template>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item
                label="可抵扣税率:"
                prop="deductibleTaxRate"
              >
                <el-input
                  class="amount-input"
                  placeholder="请输入可抵扣税率"
                  v-model="formData.deductibleTaxRate"
                  @input="limitInputModify($event, 'deductibleTaxRate')"
                  @keydown.enter.native.prevent="handle"
                >
                  <i
                    slot="suffix"
                    :style="{ color: '#323232', paddingRight: 6 + 'px' }"
                  >
                    %
                  </i>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="可抵扣税额:" prop="deductibleTax">
                <el-input
                  class="amount-input"
                  placeholder="-"
                  v-model="formData.deductibleTax"
                  disabled
                />
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item
                prop="exContractAmount"
                class="label-style"
              >
                <span slot="label">合同金额<br />(不含抵扣税):</span>
                <el-input
                  v-model="formData.exContractAmount"
                  class="amount-input"
                  placeholder="-"
                  disabled
                />
              </el-form-item>
            </el-col>
          </el-row>
        </section>
      </div>
      <!-- 变更后 end -->

      <!-- 变更前 start -->
      <div class="detail-card">
        <header class="card-header">
          <h4 class="second-title">变更前</h4>
        </header>
        <section class="card-content">
          <el-row :gutter="20">
            <el-col :span="6">
              <el-form-item label="币种:" :rules="{required: true}">
                <el-select
                  v-model="oldFormData.currency"
                  style="width: 100%"
                  :disabled="true"
                  :clearable="true"
                >
                  <template v-for="(item, index) of currencyList">
                    <el-option
                      :key="index"
                      :label="item.value"
                      :value="item.code"
                    />
                  </template>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item
                class="label-style"
                :rules="{ required: true }"
              >
                <span slot="label">合同金额(含税)<br />(A):</span>
                <el-input
                  class="amount-input"
                  placeholder="请输入金额"
                  v-model="oldFormData.contractAmount"
                  @input="limitInputModify($event, 'contractAmount')"
                  @keydown.enter.native.prevent="handle"
                  disabled
                />
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item
                prop="costAmount"
                class="label-style"
              >
                <span slot="label">不计成本金额<br />(B):</span>
                <el-input
                  class="amount-input"
                  placeholder="-"
                  v-model="oldFormData.costAmount"
                  @input="limitInputModify($event, 'costAmount')"
                  @keydown.enter.native.prevent="handle"
                  disabled
                />
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item
                prop="effContractAmount"
                class="label-style"
              >
                <span slot="label">有效签约金额<br />(C=A-B):</span>
                <el-input
                  class="amount-input"
                  placeholder="-"
                  v-model="oldFormData.effContractAmount"
                  disabled
                />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="6">
              <el-form-item label="发票类型:" :rules="{required: true}">
                <el-select
                  v-model="oldFormData.invoiceType"
                  style="width: 100%"
                  disabled
                >
                  <template v-for="(item, index) of invoiceTypeList">
                    <el-option
                      :key="index"
                      :label="item.label"
                      :value="item.code"
                    />
                  </template>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item
                label="可抵扣税率:"
                prop="deductibleTaxRate"
              >
                <el-input
                  class="amount-input"
                  placeholder="-"
                  v-model="oldFormData.deductibleTaxRate"
                  @input="limitInputModify($event, 'deductibleTaxRate')"
                  @keydown.enter.native.prevent="handle"
                  disabled
                >
                  <i
                    slot="suffix"
                    :style="{ color: '#323232', paddingRight: 6 + 'px' }"
                  >
                    %
                  </i>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="可抵扣税额:" prop="deductibleTax">
                <el-input
                  class="amount-input"
                  placeholder="-"
                  v-model="oldFormData.deductibleTax"
                  disabled
                />
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item
                prop="exContractAmount"
                class="label-style"
              >
                <span slot="label">合同金额<br />(不含抵扣税):</span>
                <el-input
                  v-model="oldFormData.exContractAmount"
                  class="amount-input"
                  placeholder="-"
                  disabled
                />
              </el-form-item>
            </el-col>
          </el-row>
        </section>
      </div>
      <!-- 变更后 end -->

      <!-- 费项分摊 start-->
      <div class="detail-card">
        <header class="card-header cost">
          <h4 class="second-title">费项分摊</h4>
          <button class="btn btn-primary" @click.prevent="addCostListModify">
            <i class="el-icon-circle-plus-outline iconStyle"></i>
            新增
          </button>
        </header>
        <section class="card-content">
          <el-table
            :data="formData.costExpenseList"
            class="cost-table"
            ref="costExpenseList"
            style="width: 100%"
            border
            fit
          >
            <div slot="empty" class="empty-box">
              <button class="btn" @click.prevent="addCostListModify">
                <i class="el-icon-circle-plus-outline"></i>
              </button>
              <div><span>请点击【新增】按钮，添加费项分摊信息</span></div>
            </div>
            <el-table-column label="责任人" prop="operatorNo">
              <template slot-scope="scope">
                <el-form-item
                  class="cost-card"
                  :prop="'costExpenseList.' + scope.$index + '.operatorNo'"
                  :rules="costFormRules.operatorNo"
                >
                  <el-select
                    filterable
                    v-model="scope.row.operatorNo"
                    size="mini"
                    style="width: 100%"
                  >
                    <el-option
                      v-for="(item, index) of dutyPeoList"
                      :key="index"
                      :label="item.name"
                      :value="item.id"
                    />
                  </el-select>
                </el-form-item>
              </template>
            </el-table-column>
            <el-table-column label="费项" prop="costItemsId">
              <template slot-scope="scope">
                <el-form-item
                  class="cost-card"
                  :prop="
                    'costExpenseList.' + scope.$index + '.costItemsId'
                  "
                  :rules="costFormRules.costItemsId"
                >
                  <el-select
                    filterable
                    v-model="scope.row.costItemsId"
                    size="mini"
                    style="width: 100%"
                  >
                    <el-option
                      v-for="(item, index) of costDetailList"
                      :key="index"
                      :label="item.F_CAPTION"
                      :value="item.FK_KMBM"
                    />
                  </el-select>
                </el-form-item>
              </template>
            </el-table-column>
            <el-table-column label="预算分摊部门" prop="costItemsDept">
              <template slot-scope="scope">
                <el-form-item
                  class="cost-card"
                  :prop="
                    'costExpenseList.' + scope.$index + '.costItemsDept'
                  "
                  :rules="costFormRules.costItemsDept"
                >
                  <el-select
                    filterable
                    v-model="scope.row.costItemsDept"
                    size="mini"
                    style="width: 100%"
                  >
                    <el-option
                      v-for="(item, index) of costShareDepartList"
                      :key="index"
                      :label="item.F_CAPTION"
                      :value="item.F_ID"
                    />
                  </el-select>
                </el-form-item>
              </template>
            </el-table-column>
            <el-table-column label="分摊金额(含税)" prop="amount" align="right">
              <template slot-scope="scope">
                <el-form-item
                  class="cost-card"
                  :prop="'costExpenseList.' + scope.$index + '.amount'"
                  :rules="costFormRules.amount"
                >
                  <el-input
                    size="mini"
                    v-model="scope.row.amount"
                    class="amount-input"
                    style="width: 100%"
                    @input="
                      () => {
                        scope.row.amount = limitInput(scope.row.amount);
                      }
                    "
                    @keydown.enter.native.prevent="handle"
                  />
                </el-form-item>
              </template>
            </el-table-column>
            <el-table-column label="操作" width="60px" align="left">
              <template slot-scope="scope">
                <div>
                  <el-button
                    class="operate-btn"
                    type="text"
                    @click="deleteCostModify(scope.$index, scope.row)"
                  >
                    删除
                  </el-button>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </section>
      </div>
      <!-- 费项分摊 end -->

      <!-- 框架协议修改说明 start -->
      <div class="detail-card">
        <header class="card-header describe">
          <h4 class="second-title">框架协议修改说明</h4>
          <span>请说明首付款、过程付款、尾款的付款条件、时间及金额</span>
        </header>
        <section class="card-content">
          <el-row>
            <el-col :span="18">
              <el-form-item label="说明信息:" prop="infoChangeCause">
                <el-input
                  type="textarea"
                  size="small"
                  placeholder="请输入内容"
                  :autosize="{ minRows: 2, maxRows: 6 }"
                  maxlength="5000"
                  show-word-limit
                  v-model="formData.infoChangeCause"
                >
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </section>
      </div>
      <!-- 框架协议修改说明 end -->
      <div class="detail-card no-bottom-border">
        <header class="card-header">
          <h4 class="second-title">文档</h4>
        </header>
        <section class="card-content">
          <Attach
            ref="attach"
            :label="'附件：'"
            :prop="'attachFileList'"
            :fileUploadList="formData.attachFileList"
            @upload="uploadFile"
          >
          </Attach>
        </section>
      </div>
    </el-form>
  </div>
</template>

<script>
import Attach from "@/components/common/Attach.vue";
import utils from '@/utils/index'
export default {
  props: {
    modifyData: { // 传进来的数据
      type: Object,
      default() {
        return {};
      }
    },
    currencyList: { // 币种下拉选项
      type: Array,
      default() {
        return [];
      }
    },
    subList: { // 签约主体
      type: Object,
      default() {
        return {};
      }
    },
    invoiceTypeList: { // 发票类型下拉选项
      type: Array,
      default() {
        return [];
      }
    },
    forTypeList: { // 形成形式下拉选项
      type: Array,
      default() {
        return []
      }
    },
    costDetailList: { // 费项下拉选项
      type: Array,
      default() {
        return []
      }
    },
    costShareDepartList: { // 预算分摊部门下拉选项
      type: Array,
      default() {
        return []
      }
    },
    dutyPeoList: { // 责任人下拉选项
      type: Array,
      default() {
        return []
      }
    },
    currentUserName: { // 当前登录人
      type: String,
      default: ''
    },
    type: { // 在新增还是修改时调用
      type: String,
      default: 'create'
    }
  },
  components: { Attach },
  data() {
    var modifyFiles = (rule, value, callback) => {
      let index = this.formData.attachFileList.findIndex(v => v.status === "success")
      if (index == '-1') {
        callback(new Error('附件不能为空'))
      } else  {
        callback()
      }
    }
    return {
      formData: {},
      formRules: {
        contractName: [{ required: true, message: "请输入合同名称" }],
        contractDate: [{ required: true, message: "请选择经办日期" }],
        projectGoalFlag: [{ required: true, message: "请选择关联项目" }],
        needPay: [{ required: true, message: "请选择是否需要付款" }],
        supplyStoreFlag: [{ required: true, message: "请选择是否供方合同" }],
        modifyMoneyType: [{ required: true, message: "请选择修改类型" }],
        modifyMoneyNum: [{ required: true, message: "请输入金额"}],
        currency: [{ required: true, message: "请选择币种" }],
        contractAmount: [{ required: true, message: "请填写合同金额" }],
        invoiceType: [{ required: true, message: "请选择发票类型" }],
        ourCompany_modify: [{ required: true, type: 'array', message: "请选择我方签约主体", trigger: 'change' }],
        supplierCompany_modify: [{ required: true, type: 'array', message: "请选择对方签约主体", trigger: 'change' }],
        attachFileList: [{ required: true, validator: modifyFiles }]
      },
      costFormRules:{
        operatorNo: [{ required: true, message: "请选择责任人" }],
        costItemsId: [{ required: true, message: "请选择费项" }],
        costItemsDept: [{ required: true, message: "请选择费项分摊部门" }],
        amount: [{ required: true, message: "请输入金额"}]
      },
      modifyMoneyTypeList: [ // 修改类型下拉框选项
        {
          label: '追加',
          value: '1'
        },
        {
          label: '减少',
          value: '2'
        }
      ],
      oldFormData: {}, // 保存修改前数据
      isShowTips: false
    };
  },
  watch: {
    formData: {
      handler(newVal) {
        if (![undefined, null, ''].includes(newVal.contractAmount)) {
          // 计算有效签约金额
          if (newVal.costAmount ) {
            if(parseFloat(newVal.contractAmount) >= parseFloat(newVal.costAmount)) {
              this.formData.effContractAmount = utils.BigNumberCacl(this.formData.contractAmount, this.formData.costAmount, '-')
            }else {
              this.$message.warning('有效签约金额不能为负数')
              this.formData.effContractAmount = this.formData.contractAmount
              this.formData.costAmount = ''
            }
          } else {
            this.formData.effContractAmount = utils.BigNumberCacl(this.formData.contractAmount, '0', '-')
          }

          // 计算可抵扣税额 合同金额（不含抵扣税）
          if (this.formData.deductibleTaxRate) {
            let middles = utils.BigNumberCacl(this.formData.contractAmount, (1 + (newVal.deductibleTaxRate / 100)), '/')
            let tax = utils.BigNumberCacl((newVal.deductibleTaxRate / 100), middles, '*')
            this.formData.deductibleTax = tax
            this.formData.exContractAmount = utils.BigNumberCacl(this.formData.contractAmount, this.formData.deductibleTax, '-')
          } else {
            this.formData.exContractAmount = utils.BigNumberCacl(this.formData.contractAmount, '0', '-')
            this.formData.deductibleTax = null
          }
        } else {
          this.formData.effContractAmount = null
          this.formData.exContractAmount = null
          this.formData.deductibleTax = null
        }
      },
      deep: true
    }
  },
  created() {
    this.initPageInfo()
  },
  methods: {
    // 当修改金额改变时，影响合同金额(含税)
    handleModifyMoneyNumChange(event) {
      this.$limitRule(event, 'modifyMoneyNum', this.formData)
      const changeValue = utils.BigNumberCacl(this.oldFormData.contractAmount, this.formData.modifyMoneyNum || '0', this.formData.modifyMoneyType === '1' ? '+' : '-')
      if(parseFloat(changeValue) < 0) {
        this.$message.warning('当修改类型为减少时，本次修改金额应小于等于合同金额')
        this.$set(this.formData, 'modifyMoneyNum', '')
        this.$set(this.formData, 'contractAmount', this.oldFormData.contractAmount)
        return
      }else {
        this.formData.contractAmount = this.$set(this.formData, 'contractAmount', changeValue)
      }
    },
    // 当修改类型改变时，影响合同金额(含税)
    handleModifyMoneyTypeChange(ev){
      const changeValue = utils.BigNumberCacl(this.oldFormData.contractAmount, this.formData.modifyMoneyNum || '0', ev === '1' ? '+' : '-')
      if(parseFloat(changeValue) < 0) {
        this.$message.warning('当修改类型为减少时，本次修改金额应小于等于合同金额')
        this.$set(this.formData, 'modifyMoneyNum', '')
        this.$set(this.formData, 'contractAmount', this.oldFormData.contractAmount)
        return
      }else {
        this.formData.contractAmount = this.$set(this.formData, 'contractAmount', changeValue)
      }
    },
    // 当合同金额(含税)改变时，影响修改类型，修改金额
    handleContractAmountChange(ev) {
      this.$limitRule(ev, 'contractAmount', this.formData)
      let changeValue = utils.BigNumberCacl(this.oldFormData.contractAmount || '0', this.formData.contractAmount || '0', '-')
      // 当changeValue > 0 说明是减少，当< 0 说明是追加
      this.formData.modifyMoneyType = parseFloat(changeValue) > 0 ? '2' : '1'
      this.$set(this.formData, 'modifyMoneyNum', Math.abs(changeValue))
    },
    // 初始化数据
    initPageInfo() {
      // 存储旧的数据
      this.oldFormData = this.getOldFormData()
      const data = {
        infoChangeCause: this.type === 'modify' ? this.modifyData.infoChangeCause : '' ,
        attachFileList: this.modifyData.attachFileList,
        contractDate: this.type === 'modify' ? this.modifyData.contractDate : new Date(),
        contractName: this.modifyData.contractName,
        contractNo: this.modifyData.contractNo,
        contractNote: this.modifyData.contractNote,
        contractType: this.modifyData.contractType,
        contractTypeValue: this.modifyData.contractTypeValue,
        costExpenseList: this.modifyData.costExpenseList,
        currency: this.modifyData.currency,
        forType: this.modifyData.forType,
        invoiceType: this.modifyData.invoiceType,
        needPay: this.modifyData.needPay,
        operatorName: this.currentUserName,
        ourCompany: this.modifyData.ourCompanyList,
        ourCompany_modify: utils.formatSubId(this.modifyData.ourCompanyId),
        projectGoalFlag: this.modifyData.projectGoalFlag,
        projectId: utils.formatSubName(this.modifyData.projectId),
        projectName: utils.formatSubName(this.modifyData.projectName),
        supplierCompany: this.modifyData.supplierCompanyList,
        supplierCompany_modify: utils.formatSubId(this.modifyData.supplierCompanyId),
        supplyStoreFlag: this.modifyData.supplyStoreFlag || '0',
        modifyMoneyType: this.modifyData.modifyMoneyType ? ''+ this.modifyData.modifyMoneyType : '1',
        modifyMoneyNum: this.modifyData.modifyMoneyNum
      }
      data.attachFileList.forEach((item, index) => {
        item.status = "success";
        item.uid = "a" + index;
      });
      if(this.type === 'modify') {
        // 当时框架类协议编辑时，变更前数据取modifyData的oldModify，变更后的数据取modifyData的数据
        data.contractAmount= this.modifyData.contractAmount
        data.costAmount =  this.modifyData.costAmount ? parseFloat(this.modifyData.costAmount).toFixed(2) : ''
        data.deductibleTax = this.modifyData.deductibleTax
        data.deductibleTaxRate =  this.modifyData.deductibleTaxRate ? parseFloat(this.modifyData.deductibleTaxRate) * 100 : ''
        data.effContractAmount = this.modifyData.effContractAmount
        data.exContractAmount = this.modifyData.exContractAmount
      }else {
        // 当是新增时，判断返回数据中是否存在 modifyLastVo 最新一次修改的框架类数据，有的话取modifyLastVo 数据，没有取外层数据
        // 因为新增时初始值变更前和变更后一致，oldFormData中已做了上述判断，所以可以直接用oldFormData的值
        data.contractAmount= this.oldFormData.contractAmount
        data.costAmount =  this.oldFormData.costAmount
        data.deductibleTax = this.oldFormData.deductibleTax
        data.deductibleTaxRate =  this.oldFormData.deductibleTaxRate
        data.effContractAmount = this.oldFormData.effContractAmount
        data.exContractAmount = this.oldFormData.exContractAmount
      }
      data.fixContractType = String(this.modifyData.fixContractType)
      this.formData = data
    },
    // 上传数据
    uploadFile(params){
      if (params.length > 0) {
        this.$refs.attach?.$refs.file_rule?.clearValidate()
      }
      params.forEach(item => {
        item.fileCategory = "CONTRACT"
      })
      this.formData.attachFileList = params
    },
    // 添加费项
    addCostListModify() {
      this.formData.costExpenseList.push({})
    },
    // 删除某一费项
    deleteCostModify(index, row) {
      this.formData.costExpenseList.splice(index, 1)
    },
    // 提交
    submitForm() {
      const result = { flag: false }
      this.$refs.formData.validate(valid => {
        result.flag = valid
      })
      const data = this.storageForm()
      return result.flag ? {...result, ...data} : result
    },
    // 暂存，不验证必填
    storageForm() {
      this.formData.amount = this.formData.contractAmount
      // 将费项列表中加入costItemsName值和costItemsDeptName值
      if(this.formData.costExpenseList.length !== 0) {
        this.formData.costExpenseList.forEach(item => {
          this.costDetailList.forEach(it =>{
            if(it.FK_KMBM === item.costItemsId) {
              item.costItemsName = it.F_CAPTION
            }
          })
          this.costShareDepartList.forEach(it => {
            if(it.F_ID === item.costItemsDept) {
              item.costItemsDeptName = it.F_CAPTION
            }
          })
        })
      }
      return {...this.formData}
    },
    // 限制输入
    limitInputModify(value, name) {
      return this.$limitRule(value,name,this.formData)
    },
    // 处理金额输入框的校验
    limitInput(value, name) {
      return this.$limitRule(value,name)
    },
    // 变更前数据
    getOldFormData() {
      const props = ['currency', 'contractAmount', 'invoiceType', 'costAmount', 'effContractAmount', 'deductibleTaxRate', 'deductibleTax', 'exContractAmount']
      let formData = {}
      let oldFormData = {}
      if(this.type === 'modify') {
        formData =  this.modifyData.modifyLastVo
        formData.contractAmount = formData.amount
      }else {
        formData =  this.modifyData.modifyLastVo ? this.modifyData.modifyLastVo : this.modifyData
        formData.contractAmount = this.modifyData.modifyLastVo ? formData.amount : formData.contractAmount
      }

      props.map(item => {
        oldFormData[item] = formData[item]
        if(['deductibleTax', 'exContractAmount', 'contractAmount', 'effContractAmount','costAmount'].includes(item)) {
          oldFormData[item] = oldFormData[item] ? parseFloat(oldFormData[item]).toFixed(2) : ''
        }
        if(['deductibleTaxRate'].includes(item)) {
          oldFormData[item] = oldFormData[item] ? (oldFormData[item] * 100).toFixed(2) : ''
        }
      })
      return oldFormData
    },
    handle(){},
    // 当经办日期小于当前日期时做出提示
    handelContractDateChange(val) {
      let nowDate = new Date();
      let y = nowDate.getFullYear();
      let m = (nowDate.getMonth() + 1).toString();
      let d = nowDate.getDate().toString();

      m = m.length == 1 ? "0" + m : m;
      d = d.length == 1 ? "0" + d : d;

      let today = y + "-" + m + "-" + d;
      this.isShowTips = val < today
    }
  }
};
</script>
<style lang="less" scoped>
.contract-info {
  padding-bottom: 18px;
  border-bottom: 1px solid #ebebeb;
}
.detail-card {
  .describe {
      h4,
      span {
        display: inline-block;
      }
      span {
        color: #999999;
        font-size: 12px;
        margin-left: 6px;
      }
    }
}
.date-tip {
  font-size: 12px;
  position: relative;
  top: -8px;
  color: #808898;
}
.cost-table {
  /deep/ .cell {
    height: auto !important;
  }
  /deep/ .el-form-item__error {
    position: relative;
    text-align: left;
  }
}
</style>
